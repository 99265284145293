import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['button_send', 'button_enrich_csv', 'file_field']
  static values = { url: String }

  send (event) {
    this.searchAllRadioButton(event.target)
    this.creationSendEmailForm()
  }

  enrich (event) {
    this.searchAllRadioButton(event.target)
    document.getElementById('enrich_csv_submit_button').style.display = 'block'
    document.getElementById('email_form').style.display = 'none'
  }

  searchAllRadioButton (target) {
    var all = document.getElementsByClassName('button_radio_send_and_enrich')

    _.each(all, function (element) {
      if (element !== target) {
        element.parentNode.classList.remove('active')
        target.parentNode.classList.add('active')
        element.checked = false
        target.checked = true
      }
    })
  }

  creationSendEmailForm () {
    var element = document.querySelector('trix-editor')
    document.getElementById('enrich_csv_submit_button').style.display = 'none'
    document.getElementById('email_form').style.display = 'block'

    document.getElementById('push_email_campaign_job_report_attributes_push_campaign_mode').value = 'SEND_EMAIL'

    document.getElementById('push_email_campaign_email_subject').value = "Votre devis de livraison - {{var:organization_name:''}} - ThePackengers"
    document.getElementById('push_email_campaign_email_content').style.border = '1px solid black'

    var vale = `<p><i>English below</i></p>
                <p>Bonjour {{var:customer_name:''}}</p>
                </ br>
                <p>En accord avec <strong>{{var:organization_name:''}}</strong> nous avons le plaisir de vous proposer un devis de transport.</p>
                <p>Veuillez trouver l’offre de <strong>ThePackengers</strong> sur le lien ci-dessous :</p>
                <p><a href="{{var:order_url:''}}">{{var:order_url:''}}</a></p>
                </ br>
                <p>Si ce devis ne correspondait pas à votre attente, n'hésitez pas à contacter le département shipping de <strong>{{var:organization_name:''}}</strong> afin d'étudier les solutions alternatives.</p>
                <p>Par ailleurs, nous vous rappelons que conformément aux conditions générales de <strong>{{var:organization_name:''}}</strong> des frais de stockage sont susceptibles de vous être facturés dès le 15ème jour suivant la vente. Nous vous recommandons d’en tenir compte lors de l’organisation du transport et de l’enlèvement de vos achats.</p>
                </ br>
                <p>Vous recevez cet email car vous avez accepté d’être contacté par le partenaire transport de <strong>{{var:organization_name:''}}</strong>.</p><p>Vous pouvez vous désinscrire à tout moment sur simple demande par email.</p>
                </ br>
                </ br>
                <p>Nous restons à votre disposition pour tout complément d'information.</p>
                </ br>
                <p>Bien cordialement,</p>
                </ br>
                <p>ThePackengers</p>
                </ br>
                <p>******************</p>
                </ br>
                <p>Hello {{var:customer_name:''}}</p>
                </ br>
                <p>In partnership with <strong>{{var:organization_name:''}}</strong>, we are pleased to offer you a shipping estimate for your purchase.</p><p>Please find the ThePackengers offer on the link below:</p>
                <p><a href="{{var:order_url:''}}">{{var:order_url:''}}</a></p>
                </ br>
                <p>If this estimate does not meet your requirements, please contact the <strong>{{var:organization_name:''}}</strong> Shipping Department directly to inquire on alternative transport solutions.</p>
                <p>Additionally, we remind you that in accordance with <strong>{{var:organization_name:''}}</strong>’s Terms and Conditions, storage fees may be charged starting from the 15th day after the sale date. Please take this point into account when organizing the collection and shipment of your purchase.</p>
                </ br>
                <p>You have received this email because you have agreed to be contacted by the transport partner of <strong>{{var:organization_name:''}}</strong>.</p><p>You may unsubscribe from this service at any time through request by email.</p>
                </ br>
                </ br>
                <p>We remain at your disposal if you have any further questions or concerns.</p>
                <p>With our best regards,</p>
                </ br>
            <p>ThePackengers</p>`
    document.getElementById('push_email_campaign_email_body').value = vale
    element.editor.insertHTML(vale)
  }
}
