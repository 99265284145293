import { Controller } from "@hotwired/stimulus"
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

export default class extends Controller {
  static targets = ['element']

  static values = {
    autoSubmit: { type: Boolean, default: false },
    preventValidation: { type: Boolean, default: false },
    buttonSubmit: { type: Boolean, default: false }
  }

  connect() {
    const swiper = new Swiper('.swiper', {
      slidesPerView: 2,
      spaceBetween: 20,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        768: {
          slidesPerView: 4,
        },
        1024: {
          slidesPerView: 5,
        },
      },
    });
  }
}
