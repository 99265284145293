import ApplicationController from './application_controller'
import Uppy from '@uppy/core'
import Dashboard from '@uppy/dashboard'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'

export default class extends ApplicationController {
  static targets = ['container', 'packingForm', 'imagePackings']

  connect () {
    this.uploads = []

    this.uppy = new Uppy({
      autoProceed: true,
      allowMultipleUploads: true,
      restrictions: {
        allowedFileTypes: ['image/*', '.jpg', '.jpeg', '.png', '.gif']
      }
    })
      .use(Dashboard, {
        target: this.containerTarget,
        hideUploadButton: true,
        proudlyDisplayPoweredByUppy: false,
        inline: true,
        width: '100%'
      })
  }

  retrievePictures () {
    this.uppy.getFiles().forEach((uppyFile) => {
      this.uploads.push(uppyFile['data'])
    })

    return this.uploads
  }

  blobToFile (theBlob, fileName) {
    const upload = document.querySelector('[data-controller="resize-uploaded-files"]')
    let controller = this.application.getControllerForElementAndIdentifier(upload, 'resize-uploaded-files')
    const blobFile = eval(controller['blobToFile'](theBlob, fileName))

    return blobFile
  }

  resizePhotos (settings) {
    const upload = document.querySelector('[data-controller="resize-uploaded-files"]')
    let controller = this.application.getControllerForElementAndIdentifier(upload, 'resize-uploaded-files')
    const blobFile = eval(controller['resizePhotos'](settings))

    return blobFile
  }

  async addToFormData (formData, array, requestValue) {
    const upload = document.querySelector('[data-controller="resize-uploaded-files"]')
    let controller = this.application.getControllerForElementAndIdentifier(upload, 'resize-uploaded-files')
    const blobFile = await eval(controller['addToFormData'](formData, array, requestValue))

    return blobFile
  }

  async uploadAfterPacking () {
    this.uploads = this.retrievePictures()
    let action = this.packingFormTarget
    let dataset = event.currentTarget.dataset
    let directlyUploaded = Boolean(dataset.directlyUpload)
    let url = dataset.url

    let body = new FormData()
    body = await this.addToFormData(body, this.uploads, 'order[packing_uploads][]')

    body.append('authenticity_token', action.children[0].value)
    body.append('after_submit', 'true')
    if(directlyUploaded) {
      body.append('directly_uploaded', directlyUploaded)
    }

    if (this.retrievePictures().length >= 0) {
      Rails.ajax({
        url: url,
        type: 'PATCH',
        data: body,
        success: (data) => { window.location.reload() }
      })
    }
  }
}
